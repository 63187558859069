<template>
  <div>

    <v-tabs v-model="defaultTab">
      <v-tab href="#profile" ><v-icon left>
          mdi-account
        </v-icon>Profile</v-tab>
      <v-tab href="#files"><v-icon left>
          mdi-file
        </v-icon>Files</v-tab>
      </v-tabs>
        <v-tabs-items v-model="defaultTab">
      <v-tab-item key="profile" value="profile">
        <v-container fluid>
          <v-row>
            <v-card>

              <v-card-title>
                <span class="text-h5">Domestic Helper Profile</span>
              
                <span
                  class='ml-10'
                  v-if='mode === "application"'
                >Phone Number : {{dataFromDB.phoneNumber}}</span>
                <v-spacer></v-spacer>
                <input
                  type="color"
                  v-model="bgColor"
                />
                <v-btn 
                  class='mr-3'
                  v-if='dataFromDB && dataFromDB.caseId'
                  :to='`/case/${dataFromDB.caseId}`'
                >Go to Case</v-btn>
                <v-btn
                  class='mr-3'
                  v-if='mode !== "application" && mode !== "new" '
                  @click='downloadPDF'
                >Download PDF</v-btn>
                <v-btn
                  :loading='uploading'
                  @click='upload'
                >Upload Photo</v-btn>
                <input
                  id='fileid'
                  type='file'
                  @change="Handlechange"
                  hidden
                />
                <img
                  class='ml-3'
                  width="150"
                  v-if='formData.fileLocation'
                  :src='`https://storage.googleapis.com/starjasmine-aacba.appspot.com/${formData.fileLocation}`'
                />
              </v-card-title>
              
              <v-card-text>
                
                <v-checkbox
      v-model="formData.externalData"
      :label="`External Biodata`"
      @change="toggleRequiredFields"
    ></v-checkbox>

                <v-container>
                 
                  <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                  >
                    <v-row>

                      <v-col
                        cols="6"
                        v-if='mode !== "application"'
                      >
                        <v-select
                          :rules="requiredRule"
                          dense
                          :items="['Internal use only','Share with everyone']"
                          label="Visibility Setting*"
                          v-model="formData.visibility"
                          required
                        ></v-select>
                      </v-col>
                      <v-col
                        cols="6"
                        v-if='mode !== "application"'
                      >
                        <v-text-field
                          dense
                          label="Price for processing this maid (please specific)*"
                          prefix="$"
                          :disabled='formData.visibility !== "Share with everyone"'
                          v-model="formData.price"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Still Valid?"
                          v-model="formData.valid"
                          required
                        ></v-select>
                      </v-col>

                      <span class="text-h6 ma-2">個人資料 Personal Particulars</span>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Portfolio ID*"
                          :rules="requiredRule"
                          v-model="formData.portfolioId"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Video Link"
                          v-model="formData.videoLink"
                          required
                        ></v-text-field>
                      </v-col>

                      <v-col cols="6">
                        <v-text-field
                          dense
                          label="Passport Number"
                          v-model="formData.passportNum"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          dense
                          label="ID Number e.g. N999999(A)"
                          :rules="hkIDRule"
                          v-model="formData.idNumber"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :rules="requiredRule"
                          :items="['Finished Contract','Special Case', 'Term/Break','Oversea']"
                          label="Job Status*"
                          required
                          v-model="formData.jobstatus"
                          @change='onJobStatusChange'
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :items="['Oversea','Local']"
                          label="Status"
                          v-model="formData.status"
                          disabled
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-dialog
                          ref="dialog"
                          v-model="modal"
                          :return-value.sync="formData.submitDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              v-model="formData.submitDate"
                              label="Submission Date"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="requiredRule"
                              required
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.submitDate"
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modal = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog.save(formData.submitDate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Name*"
                          :rules="requiredRule"
                          v-model="formData.name"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          label="Name on ID/Passport"
                          v-model="formData.nameOnIDPassport"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-dialog
                          ref="dialog3"
                          v-model="modal3"
                          :return-value.sync="formData.dob"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :rules="requiredRule"
                              required
                              dense
                              v-model="formData.dob"
                              label="Date of Birth*"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.dob"
                            @change='changeDOB'
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modal3 = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog3.save(formData.dob)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          dense
                          disabled
                          label="Zodiac"
                          :rules="requiredRule"
                          v-model="formData.Zodiac"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="3">
                        <v-text-field
                          dense
                          disabled
                          label="Chinese Zodiac"
                          :rules="requiredRule"
                          v-model="formData.chineseZodiac"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          disabled
                          label="Age*"
                          v-model="formData.age"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          :rules="requiredRule"
                          required
                          label="Mobile Number"
                          v-model="formData.phoneNumber"
                        ></v-text-field>
                      </v-col>
                     
                      <v-col cols="12">
                        <v-dialog
                          ref="dialog2"
                          v-model="modal2"
                          :return-value.sync="formData.availabilityDate"
                          persistent
                          width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              dense
                              v-model="formData.availabilityDate"
                              label="Availabile on"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :rules="requiredRule"
                              required
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="formData.availabilityDate"
                            scrollable
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="modal2 = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.dialog2.save(formData.availabilityDate)"
                            >
                              OK
                            </v-btn>
                          </v-date-picker>
                        </v-dialog>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          label="Nationality*"
                          :items="['Filipino','Indonesian','Others']"
                          :rules="requiredRule"
                          v-model="formData.nationality"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['no preference','1-2','3-4','5+']"
                          label="Preferred Family Size"
                          v-model="formData.preferredfamilysize"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Junior High','High School','College','Others']"
                          label="Education"
                          :rules="requiredRule"
                          v-model="formData.education"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Single','Married','Separated','Others']"
                          v-model="formData.maritalStatus"
                          label="Marital Status"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          required
                          :rules="requiredRule"
                          label="Number of children and age*"
                          v-model="formData.childenNumAndAge"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          dense
                          required
                          :rules="requiredRule"
                          label="Number of siblings and ranking*"
                          v-model="formData.siblingsNumAndRanking"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="['Christian','Roman Catholic','Protestantism','Muslim','Others']"
                          label="Religion"
                          v-model="formData.religion"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col
                        sm="6"
                        cols="12"
                      >
                        <v-text-field
                          dense
                          :rules="requiredRule"
                          required
                          label="Height"
                          suffix="cm/inch"
                          v-model="formData.height"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        sm="6"
                        cols="12"
                      >
                        <v-text-field
                          dense
                          label="Weight"
                          suffix="kg"
                          v-model="formData.weight"
                          :rules="requiredRule"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols='12'>
                        <span class="text-h6">語言 Language</span>
                      </v-col>
                      <v-col
                        sm="4"
                        cols="12"
                      >
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="Cantonese Skill"
                          v-model="formData.cantonese"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col
                        sm="4"
                        cols="12"
                      >
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="English Skill"
                          v-model="formData.english"
                          :rules="requiredRule"
                          required
                        ></v-select>

                      </v-col>
                      <v-col
                        sm="4"
                        cols="12"
                      >
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="Mandarin Skill"
                          v-model="formData.mandarin"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols='12'>
                        <span class="text-h6">工作技能 Working Ability</span>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="Care of Infant"
                          v-model="formData.careInfant"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="Care of Child"
                          v-model="formData.careChild"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="Care of Elderly"
                          v-model="formData.careElderly"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="Care of Disabled Person"
                          v-model="formData.careDisabled"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="Care of Bedridden Person"
                          v-model="formData.careBedridden"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="Care of Pet"
                          v-model="formData.carePet"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="Cooking"
                          v-model="formData.cooking"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="General Housework"
                          v-model="formData.generalHousework"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          dense
                          :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                          item-text="text"
                          item-value="val"
                          label="Car Washing"
                          v-model="formData.carWashing"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>

                      <v-col cols="4">
                        <v-select
                          dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="Cleanliness"
                          v-model="formData.cleanliness"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="Discipline"
                          v-model="formData.discipline"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="Empathy"
                          v-model="formData.empathy"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="Efficiency"
                          v-model="formData.efficiency"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="Friendliness"
                          v-model="formData.friendliness"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="Hardworking"
                          v-model="formData.hardworking"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="Honesty"
                          v-model="formData.honesty"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="Patience"
                          v-model="formData.patience"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="Proactiveness"
                          v-model="formData.proactiveness"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="[1,2,3,4,5,6,7,8,9,10]"
                          label="Responsible"
                          v-model="formData.responsible"
                          required
                        ></v-select>
                      </v-col>

                      <v-col cols='12'>
                        <span class="text-h6">一般資訊 General Information</span>
                      </v-col>

                      <v-col cols="4">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Eat Pork"
                          v-model="formData.pork"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Accept day-off not on Sunday"
                          v-model="formData.acceptNoSundayOff"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Sharing room with babies/children/elderly"
                          v-model="formData.roomShare"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Afraid of pet"
                          v-model="formData.afraidPet"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Smoke"
                          v-model="formData.smoke"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Drink alcohol"
                          v-model="formData.alcohol"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Any prolonged illnessess/undergone surgery"
                          v-model="formData.illnessesSurgery"
                          :rules="requiredRule"
                          required
                        ></v-select>
                      </v-col>

                      <v-col cols="6">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="Are you willing to share job with other helpers?"
                          :rules="requiredRule"
                          v-model="formData.shareJob"
                        ></v-select>
                      </v-col>

                      <v-col cols="4">
                        <v-select
                          dense
                          :items="['Yes','No']"
                          label="CCTV ok?"
                          :rules="requiredRule"
                          v-model="formData.ccTvOk"
                        ></v-select>
                      </v-col>

                      <v-col cols="12">
                        <v-textarea
                          outlined
                          name="input-7-4"
                          label="Brief Intro"
                          v-model='formData.briefInfo'
                        ></v-textarea>
                      </v-col>

                      <v-col cols='12'>
                        <span class="text-h6">工作記錄
                          Domestic Helper Employment Record</span>
                      </v-col>

                      <v-col cols='12'>
                        <v-row>
                          <v-col
                            cols='3'
                            style='margin-top : 60px'
                          > 地區工作經驗 <br />
                            Country Experience (Years + Months)
                          </v-col>
                          <v-col cols='9'>
                            <v-row>
                              <v-col cols='6'>
                                <v-text-field
                                  dense
                                  label="Hong Kong"
                                  v-model="formData.workRecord.hk"
                                ></v-text-field>
                              </v-col>
                              <v-col cols='6'>
                                <v-text-field
                                  dense
                                  label="Middle East"
                                  v-model="formData.workRecord.middleEast"
                                ></v-text-field>
                              </v-col>
                              <v-col cols='6'>
                                <v-text-field
                                  dense
                                  label="Singapore"
                                  v-model="formData.workRecord.singapore"
                                ></v-text-field>
                              </v-col>

                              <v-col cols='6'>
                                <v-text-field
                                  dense
                                  label="Taiwan"
                                  v-model="formData.workRecord.taiwan"
                                ></v-text-field>
                              </v-col>
                              <v-col cols='6'>
                                <v-text-field
                                  dense
                                  label="Indonesia"
                                  v-model="formData.workRecord.indonesia"
                                ></v-text-field>
                              </v-col>

                              <v-col cols='6'>
                                <v-text-field
                                  dense
                                  label="Philippines"
                                  v-model="formData.workRecord.philippines"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>

                      <v-col cols='12'>
                        <span class="text-h6">家庭傭工工作經驗
                          Working Experience as Domestic Helper</span>
                      </v-col>

                      <v-col cols="12">
                        <v-btn
                          color="secondary"
                          dark
                          @click="addWorkExp"
                        >
                          Add work experience
                        </v-btn>
                        <v-card
                          v-for="(exp,index) in formData.workExp"
                          :key="index"
                        >
                          <div class='pa-5 mb-3 mt-3'>
                            <v-row>
                              <v-col cols="4">
                                <v-text-field
                                  dense
                                  :rules="requiredRule"
                                  label="Working Experience Ex:(2015) "
                                  v-model="formData.workExp[index].duration"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2">
                                <v-select
                                  dense
                                  :items="countriesList"
                                  label="Country"
                                  v-model="formData.workExp[index].country"
                                  required
                                  :rules="requiredRule"
                                ></v-select>
                              </v-col>
                              <v-col
                                cols="2"
                                v-if='formData.workExp[index].country === "Hong Kong"'
                              >
                                <v-text-field
                                  dense
                                  label="Districts in HK"
                                  v-model="formData.workExp[index].hongKongLocation"
                                  required
                                  :rules="requiredRule"
                                ></v-text-field>
                              </v-col>

                              <v-col cols="4">
                                <v-select
                                  dense
                                  :items="[1,2,3,4,5,6,7,8,9,10]"
                                  label="Number of Employer's Family Members"
                                  v-model="formData.workExp[index].houseHoldSize"
                                  required
                                ></v-select>
                              </v-col>
                              <v-col cols="2">
                                <v-text-field
                                  dense
                                  label="House Size (SQFT)"
                                  v-model="formData.workExp[index].housesize"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  dense
                                  label="Nationality of Employer"
                                  v-model="formData.workExp[index].employerNationality"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12">
                                <v-text-field
                                  dense
                                  label="Reason for leaving"
                                  v-model="formData.workExp[index].reasonToLeave"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="1">
                                <v-select
                                  dense
                                  :items="['Yes','No']"
                                  label="Care of Infant"
                                  v-model="formData.workExp[index].careInfant"
                                  required
                                ></v-select>
                              </v-col>
                              <v-col cols="3">
                                <v-text-field
                                  dense
                                  label="Addt Detail (ex: 1, 6 MO Boy)"
                                  v-model="formData.workExp[index].careInfantDetail"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="1">
                                <v-select
                                  dense
                                  :items="['Yes','No']"
                                  label="Care of Child"
                                  v-model="formData.workExp[index].careChild"
                                  required
                                ></v-select>
                              </v-col>
                              <v-col cols="3">
                                <v-text-field
                                  dense
                                  label="Addt Detail (ex: 2, 3YO Boy, 6YO Girl)"
                                  v-model="formData.workExp[index].careChildDetail"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="1">
                                <v-select
                                  dense
                                  :items="['Yes','No']"
                                  label="Care of Elderly"
                                  v-model="formData.workExp[index].careElderly"
                                  required
                                ></v-select>
                              </v-col>
                              <v-col cols="3">
                                <v-text-field
                                  dense
                                  label="Addt Detail (ex: 2, 96YO male, 87YO female)"
                                  v-model="formData.workExp[index].careElderlyDetail"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="1">
                                <v-select
                                  dense
                                  :items="['Yes','No']"
                                  label="Care of Disabled Person"
                                  v-model="formData.workExp[index].careDisabled"
                                  required
                                ></v-select>
                              </v-col>
                              <v-col cols="3">
                                <v-text-field
                                  dense
                                  label="Addt Detail (ex: on wheelchair)"
                                  v-model="formData.workExp[index].careDisabledDetail"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-select
                                  dense
                                  :items="['Yes','No']"
                                  label="Care of Bedridden Person"
                                  v-model="formData.workExp[index].careBedridden"
                                  required
                                ></v-select>
                              </v-col>
                              <v-col cols="1">
                                <v-select
                                  dense
                                  :items="['Yes','No']"
                                  label="Care of Pet"
                                  v-model="formData.workExp[index].carePet"
                                  required
                                ></v-select>
                              </v-col>
                              <v-col cols="3">
                                <v-text-field
                                  dense
                                  label="Addt Detail (ex: 6 cats, 3 dogs)"
                                  v-model="formData.workExp[index].carePetDetail"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="1">
                                <v-select
                                  :items="['Yes','No']"
                                  label="Cooking"
                                  v-model="formData.workExp[index].cooking"
                                  required
                                ></v-select>
                              </v-col>
                              <v-col cols="3">
                                <v-text-field
                                  dense
                                  label="Addt Detail(ex: chin, western)"
                                  v-model="formData.workExp[index].cookingDetail"
                                  required
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-select
                                  dense
                                  :items="['Yes','No']"
                                  label="General Housework"
                                  v-model="formData.workExp[index].generalHousework"
                                  required
                                ></v-select>
                              </v-col>
                              <v-col cols="4">
                                <v-select
                                  dense
                                  :items="['Yes','No']"
                                  label="Car Washing"
                                  v-model="formData.workExp[index].carWashing"
                                  required
                                ></v-select>
                              </v-col>
                            </v-row>
                          </div>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              style='color: red'
                              @click="removeWorkexp(index)"
                            >
                              Delete
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
                <small>*indicates required field</small>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <!-- <v-btn
                  color="blue darken-1"
                  text
                  :loading='updateLoading'
                  @click="submitData"
                >
                  {{mode === "new" ? "Create" : "Update"}}
                </v-btn> -->
                <v-btn
                  v-if='mode === "application"'
                  color="green"
                  dark
                  @click="completeReview"
                >
                  Complete Review
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item  key="files" value="files">
        <v-container fluid>

          <v-card>

            <v-card-title>
              <span class="text-h5">Files</span>

              <v-spacer></v-spacer>

              <input
                id='docid'
                type='file'
                @change="HandleFilechange"
                hidden
              />
            </v-card-title>

            <hr />
            <v-progress-linear
              v-if='loadingFiles'
              indeterminate
              height="6"
            ></v-progress-linear>
            <v-card-title>
              <span class="text-subtitle">ID</span>
              <v-spacer></v-spacer>
              <v-btn
                :loading='iduploading'
                @click='uploadDoc("id")'
              >Upload File</v-btn>
            </v-card-title>

            <v-list-item
              :key="'f'+index"
              v-for="(file,index) in idCardFiles"
            >

              <v-list-item-content>
                <a
                  @click='downloadURL(file)'
                  v-text="file.name"
                ></a>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon
                    @click="deleteFile(file)"
                    color="grey lighten-1"
                  >mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-card-title>
              <span class="text-subtitle">Passport</span>
              <v-spacer></v-spacer>
              <v-btn
                :loading='passportuploading'
                @click='uploadDoc("passport")'
              >Upload File</v-btn>

            </v-card-title>

            <v-list-item
              :key="'p' + index"
              v-for="(file,index) in passportFiles"
            >

              <v-list-item-content>
                <a
                  @click='downloadURL(file)'
                  v-text="file.name"
                ></a>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon
                    @click="deleteFile(file)"
                    color="grey lighten-1"
                  >mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

            <v-card-title>
              <span class="text-subtitle">Other Files</span>
              <v-spacer></v-spacer>
              <v-btn
                :loading='uploading'
                @click='uploadDoc'
              >Upload File</v-btn>

            </v-card-title>

            <v-list-item
              :key="'a'+index"
              v-for="(file,index) in allFiles"
            >

              <v-list-item-content>
                <a
                  @click='downloadURL(file)'
                  v-text="file.name"
                ></a>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn icon>
                  <v-icon
                    @click="deleteFile(file)"
                    color="grey lighten-1"
                  >mdi-delete</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>

          </v-card>

        </v-container>
      </v-tab-item>
      </v-tabs-items>
    

    <v-card>
    </v-card>

    <v-btn
      fab
      large
      color="primary"
      fixed
      bottom
      right
      :loading="updateLoading"
      @click="submitData"
    >
    {{mode === "new" ? "Create" : "Update"}}
    </v-btn>


  </div>
</template>



<script>
import { maidData } from "../model/model";
import { db, storage } from "../firebase/firebaseConfig";
import momentTz from "moment-timezone";
import axios from "axios";
import {
  createNewHelper,
  uploadFile,
  updateHelper,
} from "../dataservices/helperDataservies";
import { completeReview } from "../dataservices/regDataservices";
export default {
  props: {
    mode: String,
    dataFromDB: Object,
  },
  data: () => ({
    defaultTab : 'profile',
    bgColor: null,
    loadingFiles: true,
    idCardFiles: [],
    passportFiles: [],
    iduploading: false,
    passportuploading: false,
    uploadType: null,

    countriesList: [
      "Philippines",
      "Indonesia",
      "Hong Kong",
      "Singapore",
      "Taiwan",
      "Middle East",
      "Others",
    ],
    updateLoading: false,
    allFiles: [],
    fileName: null,
    fileType: null,
    uploading: false,
    base64: "",
    modal: false,
    modal2: false,
    modal3: false,
    valid: true,
    dialog: false,
    dialog2: false,
    dialog3: false,
    search: null,
    result: [],
    requiredRule: [(v) => !!v || "This Field is required"],
    hkIDRule: [
      (v) =>
        !v ||
        /^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/.test(v) ||
        "Please Enter a Valid HKID Number",
    ],
    formData: {
      externalData : false,
      franchiseeId: "",
      nameOnIDPassport: "",
      passportNum: "",
      idNumber: "",
      interviewTimeDate: null,
      visibility: null,
      price: 0,
      workExp: [],
      workRecord: {
        hk: null,
        middleEast: null,
        singapore: null,
        taiwan: null,
        indonesia: null,
        philippines: null,
      },
      Zodiac: null,
      chineseZodiac: null,
      siblingsNumAndRanking: null,
      childenNumAndAge: null,
      ccTvOk: null,
      shareJob: null,
      fileLocation: "",
      portfolioId: null,
      submitDate: null,
      availabilityDate: null,
      valid: "Yes",
      name: null,
      phone: null,
      dob: null,
      age: 0,
      nationality: null,
      education: null,
      status: null,
      jobstatus: null,
      maritalStatus: null,
      religion: null,
      height: 0,
      weight: 0,
      pic: null,
      videoLink: null,
      briefInfo: null,
      preferredfamilysize: 0,
      cleanliness: 10,
      discipline: 10,
      empathy: 10,
      efficiency: 10,
      friendliness: 10,
      hardworking: 10,
      honesty: 10,
      patience: 10,
      proactiveness: 10,
      responsible: 10,
      careInfant: 0,
      careChild: 0,
      careElderly: 0,
      careDisabled: 0,
      careBedridden: 0,
      carePet: 0,
      cooking: 0,
      generalHousework: 0,
      carWashing: 0,
      cantonese: 0,
      english: 0,
      mandarin: 0,
      pork: false,
      acceptNoSundayOff: false,
      roomShare: false,
      afraidPet: false,
      smoke: false,
      alcohol: false,
      illnessesSurgery: false,
    },
  }),
  methods: {
    toggleRequiredFields(isExternalData) {
      if (isExternalData) {
        this.requiredRule = [];
      } else {
        this.requiredRule = [(v) => !!v || "This Field is required"];
      }
    },
    calculateZodiac(dob) {
      const days = [21, 20, 21, 21, 22, 22, 23, 24, 24, 24, 23, 22];
      const signs = [
        "Aquarius",
        "Pisces",
        "Aries",
        "Taurus",
        "Gemini",
        "Cancer",
        "Leo",
        "Virgo",
        "Libra",
        "Scorpio",
        "Sagittarius",
        "Capricorn",
      ];
      let month = this.$moment(dob).month();
      let day = this.$moment(dob).day();

      if (month == 0 && day <= 20) {
        month = 11;
      } else if (day < days[month]) {
        month--;
      }
      return signs[month];
    },
    calculatechineseZodiac(year) {
      switch (year % 12) {
        case 0:
          return "Monkey";

        case 1:
          return "Rooster";

        case 2:
          return "Dog";

        case 3:
          return "Pig";

        case 4:
          return "Rat";

        case 5:
          return "Ox";

        case 6:
          return "Tiger";

        case 7:
          return "Rabbit";

        case 8:
          return "Dragon";

        case 9:
          return "Snake";

        case 10:
          return "Horse";

        case 11:
          return "Sheep";
      }
    },
    changeDOB(v) {
      let year = this.$moment(v).format("yyyy");
      let y = this.$moment().diff(v, "years");
      this.formData.age = y;
      this.formData.Zodiac = this.calculateZodiac(v);
      this.formData.chineseZodiac = this.calculatechineseZodiac(year);
    },
    onJobStatusChange(v) {
      switch (v) {
        case "Finished Contract":
        case "Special Case":
          this.formData.status = "Local";
          break;
        default:
          this.formData.status = "Oversea";
      }
    },
    async deleteFile(fileRef) {
      await fileRef.delete();
      location.reload();
    },
    async downloadPDF() {
      window.open(
        `https://asia-east2-starjasmine-aacba.cloudfunctions.net/matching/url?p=${
          this.formData.portfolioId ? this.formData.portfolioId : ""
        }&url=https://admin.starjasmine.com.hk/fetch/${this.dataFromDB.id}${
          this.bgColor ? "?color=" + this.bgColor.slice(1) : ""
        }`,
        "_blank"
      );
    },
    async completeReview() {
      if (this.formData.portfolioId === null) {
        alert("cant complete review before portfolio ID is assigned");
        return;
      }
      let newData = new maidData();
      let combineData = {
        ...newData,
        ...this.formData,
      };
      combineData.workExp = this.formData.workExp;
      combineData.id = this.dataFromDB.id;
      await db
        .collection("OldApplications")
        .doc(combineData.id)
        .set(combineData);
      combineData.submitDate = momentTz()
        .tz("Asia/Hong_Kong")
        .format("YYYY-MM-DD");
      await completeReview(combineData);
      await db.collection("register").doc(combineData.id).delete();
      alert(
        "Application review completed, helper data has been moved and deleted from applications table"
      );
      this.$router.push("/applications");
    },

    async uploadpic(id) {
      let options = {
        "content-type": "text/json",
        "Access-Control-Allow-Origin": "*",
      };
      await axios.post(
        "https://asia-east2-starjasmine-aacba.cloudfunctions.net/matching/uploadImage",
        {
          applicaiton: this.mode === "application" ? true : false,
          uploadID: id ? id : this.$route.params.id,
          fileName: this.fileName,
          baseString: this.base64,
          fileType: this.fileType,
        },
        options
      );
    },
    async Handlechange(e) {
      this.uploading = true;
      var file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = async (e) => {
        let image = e.target.result;
        this.base64 = image;
        this.base64 = this.base64.split(",")[1];
        this.fileName = file.name;
        this.fileType = file.type;
        if (this.mode === "update" || this.mode === "application") {
          await this.uploadpic();
          this.uploading = false;
          location.reload();
        } else {
          this.uploading = false;
        }
      };
      reader.readAsDataURL(file);
    },

    async HandleFilechange(e) {
      var file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = async (e) => {
        let image = e.target.result;
        this.base64 = image;
        this.base64 = this.base64.split(",")[1];
        this.fileName = file.name;
        this.fileType = file.type;

        await uploadFile(
          this.$route.params.id,
          this.fileName,
          this.base64,
          this.fileType,
          this.uploadType
        );

        switch (this.uploadType) {
          case "id":
            this.iduploading = false;
            break;
          case "passport":
            this.passportuploading = false;
            break;
          default:
            this.uploading = false;
            break;
        }

        this.uploading = false;
        location.reload();
      };
      reader.readAsDataURL(file);
    },

    uploadDoc(type) {
      this.uploadType = type;

      switch (this.uploadType) {
        case "id":
          this.iduploading = true;
          break;
        case "passport":
          this.passportuploading = true;
          break;
        default:
          this.uploading = true;
          break;
      }
      if (this.formData.portfolioId === null) {
        alert("cant upload photo before portfolio ID is assigned");
        return;
      }
      document.getElementById("docid").click();
    },

    upload() {
      if (this.formData.portfolioId === null) {
        alert("cant upload photo before portfolio ID is assigned");
        return;
      }
      document.getElementById("fileid").click();
    },
    bindData(data) {
      this.formData = { ...this.formData, ...data };
    },
    removeWorkexp(index) {
      this.formData.workExp.splice(index, 1);
    },
    addWorkExp() {
      this.formData.workExp.push({
        hongKongLocation: null,
        employerNationality: null,
        duration: 0,
        country: null,
        houseHoldSize: 0,
        reasonToLeave: null,
        otherSkill: {
          careInfantDetail: null,
          careInfant: false,
          careChildDetail: null,
          careChild: false,
          careElderly: false,
          careElderlyDetail: null,
          careDisabled: false,
          careDisabledDetail: null,
          careBedridden: false,
          carePet: false,
          carePetDetail: null,
          cooking: false,
          cookingDetail: null,
          generalHousework: false,
          carWashing: false,
        },
      });
    },
    async downloadURL(fileRef) {
      let url = await fileRef.getDownloadURL();
      return window.open(url, "_newtab");
    },
    async submitData() {

      let valid = this.$refs.form.validate();
      if(this.mode === "update" && this.formData.externalData){
        valid = true
      }
      if (valid) {
        this.updateLoading = true;
        let newData = new maidData();
        let combineData = {
          ...newData,
          ...this.formData,
        };
        combineData.workExp = this.formData.workExp;
        if (this.mode === "update") {
          combineData.phone = combineData.phone ? combineData.phone : 0;
          combineData.id = this.dataFromDB.id;
          combineData.fileLocation = !combineData.fileLocation
            ? ""
            : combineData.fileLocation;

          const update = await updateHelper(combineData.id, combineData);
          if (update === 500) {
            alert("You dont have permission to update this maid");
          } else {
            alert("profile updated.");
          }
          this.updateLoading = false;
        } else if (this.mode === "application") {
          combineData.id = this.dataFromDB.id;
          combineData.phoneNumber = this.dataFromDB.phoneNumber;
          await db.collection("register").doc(combineData.id).set(combineData);
          this.updateLoading = false;
          alert("Application Updated.");
        } else {
          // new mode
          await createNewHelper(combineData);
          alert("profile added.");
          await this.uploadpic(combineData.id);
          this.updateLoading = false;
          location.reload();
        }
      } else {
        this.$nextTick(() => {
          const el = this.$el.querySelector(
            ".v-messages.error--text:first-of-type"
          );
          this.$vuetify.goTo(el);
          return;
        });
      }
    },
  },
  async mounted() {
  
    if (this.dataFromDB) {
      this.bindData(this.dataFromDB);
      this.changeDOB(this.dataFromDB.dob);
    } else {
      this.addWorkExp();
      this.addWorkExp();
      this.addWorkExp();
    }

    if (this.formData.externalData){
      this.defaultTab = "files";
    }
    let docslistRef = await storage
      .ref(`helperFile/${this.$route.params.id}/docs`)
      .listAll();
    let idlistRef = await storage
      .ref(`helperFile/${this.$route.params.id}/idcard`)
      .listAll();
    let passportRef = await storage
      .ref(`helperFile/${this.$route.params.id}/passport`)
      .listAll();
    this.allFiles = docslistRef.items;
    this.idCardFiles = idlistRef.items;
    this.passportFiles = passportRef.items;
    this.loadingFiles = false;
  },
};
</script>


<style scoped>
.v-btn--fixed {
  position: fixed;
  z-index: 6;
}
</style>