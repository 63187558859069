import axios from "axios";
import { db } from "../firebase/firebaseConfig";
import store from "../store/index";

let options = {
	"content-type": "text/json",
	"Access-Control-Allow-Origin": "*",
};

export const createNewAgent = async (
	agentFranchasieId,
	agentName,
	agentCode,
	agentFranchasieName
) => {
	const res = await axios.post(
		"https://us-central1-starjasmine-aacba.cloudfunctions.net/adminAPI/admin/createNewAgent",
		{
			data: {
				agentFranchasieId: agentFranchasieId,
				agentFranchasieName: agentFranchasieName,
				agentName: agentName,
				agentCode: agentCode,
			},
		},
		options
	);
	return res;
};

export const createNewFranchisee = async (
	franchiseeEmail,
	franchiseePassword,
	franchiseePhone,
	franchiseeName,
	franchiseeCode,
	base64,
	address
) => {
	const res = await axios.post(
		"https://us-central1-starjasmine-aacba.cloudfunctions.net/adminAPI/admin/createNewFranchisee",
		{
			data: {
				email: franchiseeEmail,
				password: franchiseePassword,
				phone: franchiseePhone,
				name: franchiseeName,
				franchiseeCode: franchiseeCode,
				base64: base64,
				address: address,
			},
		},
		options
	);
	return res;
};

export const createNewStaff = async (Staffemail, name) => {
	let res = await axios.post(
		"https://us-central1-starjasmine-aacba.cloudfunctions.net/adminAPI/admin/createStaff",
		{
			data: {
				email: Staffemail,
				name: name,
			},
		}
	);
	return res;
};

export const createNewAdmin = async (email) => {
	let res = await axios.post(
		"https://us-central1-starjasmine-aacba.cloudfunctions.net/adminAPI/admin/grantadminMaster",
		{
			data: {
				email: email,
			},
		}
	);
	return res;
};

export const getMyFranchisee = async () => {
	const claims = store.getters.claims;
	if (claims.role === "admin") {
		return await getFranchisees();
	} else {
		return [await getFranchiseeById(claims.franchiseeId)];
	}
};

export const getFranchiseeById = async (id) => {
	let fdata = await db.collection("Franchisees").doc(id).get();

	return fdata.data();
};

export const getFranchisees = async () => {
	let fdata = await db.collection("Franchisees").get();

	return fdata.docs.map((doc) => doc.data());
};

export const generateNewMeetingLink = async () => {
	let res = await axios.post(
		"https://us-central1-starjasmine-aacba.cloudfunctions.net/adminAPI/admin/createMeetingLink",
		{}
	);
	return res;
};

export const AddUserToFranchisee = async (userId, franId, franchiseeCode) => {
	let res = await axios.post(
		"https://us-central1-starjasmine-aacba.cloudfunctions.net/adminAPI/admin/AddUserToFranchisee",
		{
			data: {
				userId: userId,
				franId: franId,
				franchiseeCode: franchiseeCode,
			},
		}
	);
	return res;
};

export const getAllAgents = async () => {
	let fdata = await db.collection("Agents").get();

	return fdata.docs.map((doc) => doc.data());
};

export const getAllFranchisees = async () => {
	let fdata = await db.collection("Franchisees").get();

	return fdata.docs.map((doc) => doc.data());
};

export const disableAgent = async (id) => {
	let res = await axios.post(
		"https://us-central1-starjasmine-aacba.cloudfunctions.net/adminAPI/admin/disableAgent",
		{
			id: id,
		}
	);
	return res;
};
