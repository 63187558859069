<template>
  <div>
    <invoice
      v-if='loadInvoiceModal'
      :caseObj='caseObj'
      :maidObj="maidObj"
      @onClose='invoiceModal = false, loadInvoiceModal = false'
      :dialog="invoiceModal"
    />
    <v-tabs>
      <v-tab><v-icon left>
          mdi-account
        </v-icon>Case</v-tab>
      <v-tab><v-icon left>
          mdi-file
        </v-icon>Files</v-tab>

      <v-tab-item key="Case">
        <v-container @paste="onPaste">

          <v-alert
            dense
            outlined
            type="error"
            v-if='errorMessageObj.length > 0'
          >
            <p
              :key='i'
              v-for='e,i in errorMessageObj'
            >{{e}}</p>
          </v-alert>

          <v-btn
            color='primary'
            :loading='saveUpdateLoading'
            @click='editMode ? edit() : save()'
          >{{editMode ? 'Update' : 'Create'}}</v-btn>
          <v-btn
            class='ml-4'
            @click='search'
            :loading='searchLoading'
          ><v-icon>mdi-magnify</v-icon>Search</v-btn>
          <v-btn
            :disabled="caseObj.caseStatus === 'Looking for Helper' || caseObj.caseStatus === 'No need Anymore'"
            class='ml-4'
            @click='validatePrintForms'
          ><v-icon class='mr-1'>mdi-printer</v-icon>Print Forms</v-btn>

          <v-btn
            :disabled='caseObj.caseStatus === "Looking for Helper"'
            class='ml-4'
            @click='invoiceModal = true, loadInvoiceModal = true'
          ><v-icon class='mr-1'>mdi-calculator</v-icon>Invoice</v-btn>

          <v-form ref="form">
            <v-row class='mt-4'>
              <v-col cols='5'>

                <v-select
                  label='Status'
                  :items="statusArr"
                  @change='updateStatus'
                  v-model='caseObj.caseStatus'
                  :disabled='editMode === false'
                >
                </v-select>
                <div class='mt-3 mb-5' v-if="caseObj.caseStatus === 'Processing' || caseObj.caseStatus === 'Completed'">
                  <v-row >

                    <v-checkbox class='mr-5' v-model="caseObj.processStatus" value="applied" dense label="Visa Applied"></v-checkbox>
                    <v-text-field v-model="caseObj.visaAppliedComment" dense label="Comments"></v-text-field>
                  </v-row>
                  <v-row >

                    <v-checkbox class='mr-5' v-model="caseObj.processStatus" value="approved" dense label="Visa Approved"></v-checkbox>
                    <v-text-field v-model="caseObj.visaApprovedComment" dense label="Comments"></v-text-field>
                  </v-row>
               
                <v-row >

                  <v-checkbox class='mr-5' v-model="caseObj.processStatus" value="verified" dense label="Contract Verified"></v-checkbox>
                  <v-text-field v-model="caseObj.contractVerifiedComment" dense label="Comments"></v-text-field>
                </v-row>
                
                <v-row >

                  <v-checkbox class='mr-5' v-model="caseObj.processStatus" value="ready" dense label="Ready for Flight (for oversea)"></v-checkbox>
                  <v-text-field v-model="caseObj.readyForFlightComment" dense label="Comments"></v-text-field>
                </v-row>
               
              </div>
                <v-text-field
                  dense
                  prefix="$"
                  v-model='caseObj.agencyFee'
                  label='Agency Fee'
                >

                </v-text-field>

                <v-menu
                  v-model="menuSignDate"
                  :close-on-content-click="true"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">

                    <v-text-field
                      v-bind="attrs"
                      v-on="on"
                      prepend-icon="mdi-calendar"
                      dense
                      v-model='caseObj.contractSignDate'
                      label='Contract Sign Date'
                    >

                    </v-text-field>

                  </template>
                  <v-date-picker
                    v-model="caseObj.contractSignDate"
                    @input="menuSignDate = false"
                  ></v-date-picker>
                </v-menu>

                <v-textarea
                  outlined
                  v-model='caseObj.caseNotes'
                  auto-grow
                  dense
                  label="Case Notes"
                  placeholder="Enter case notes here"
                ></v-textarea>

              </v-col>

              <v-col cols='7'>
                <interviewTable
                  headers:
                  :headers='InterviewTableHeaders'
                  :interviewData=interviewData
                />
              </v-col>
              <v-col>

                <v-row v-if='editMode === true'>
                  <v-col cols='12'>
                    <h3 class='mb-3'>Maid Info</h3>
                  </v-col>
                  <v-col cols='5'>
                    <v-text-field
                      v-model='caseObj.heldMaid'
                      label='Maid Held for this case'
                      :disabled='disableHeldMaidTextField'
                    >

                    </v-text-field>

                  </v-col>
                  <v-col>
                    <v-btn
                      class='mr-3'
                      @click='goToMaidPage'
                      :disabled='caseObj.heldMaid === null'
                    >
                      Maid Profile
                    </v-btn>
                    <v-btn
                      :loading='holdLoading'
                      color='info'
                      class='mr-3'
                      @click='validateMaid'
                      :disabled='disableHeldMaidBtn'
                    >
                      Confirm Hold
                    </v-btn>

                    <unHoldMaidBtn v-if="caseObj.heldMaid !== null && maidObj.id"
                      @onComplete='onUnholdComplete'
                      :disabled='caseObj.heldMaid === null'
                      :caseId='caseObj.id'
                      :heldMaid='maidObj.id'
                    />

                  </v-col>
                </v-row>
                <div v-if='caseObj.heldMaid !== null && editMode === true'>
                  <v-row>
                    <v-col>
                      <v-text-field
                        dense
                        v-model='maidObj.FirstNameOnIDPassport'
                        label='First Name on ID/Passport'
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        v-model='maidObj.MiddleNameOnIDPassport'
                        label='Middle Name on ID/Passport'
                      >
                      </v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        dense
                        v-model='maidObj.LastNameOnIDPassport'
                        label='Last Name on ID/Passport'
                      >
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    v-model='maidObj.nationality'
                    dense
                    label='Nationality'
                  >
                  </v-text-field>

                  <v-text-field
                    class='mb-3'
                    v-model='maidObj.idNumber'
                    label='ID Number e.g. N999999(A)'
                    :rules="hkIDRule"
                  >
                  </v-text-field>

                  <v-text-field
                    v-model='maidObj.passportNum'
                    dense
                    label='Passport Number'
                  >
                  </v-text-field>
                </div>
                <h3 class='mb-3'>Customer Demographics</h3>
                <v-text-field
                  dense
                  v-model='caseObj.customerId'
                  label="Customer ID"
                  placeholder="Customer ID"
                ></v-text-field>

                <v-row>
                  <v-col>
                    <v-text-field
                      dense
                      v-model='caseObj.bio.customerFirstName'
                      label="Customer First Name"
                      placeholder="Customer First Name"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      v-model='caseObj.bio.customerMiddleName'
                      label="Customer Middle Name"
                      placeholder="Customer Middle Name"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      dense
                      v-model='caseObj.bio.customerLastName'
                      label="Customer Last Name"
                      placeholder="Customer Last Name"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-text-field
                  dense
                  v-model='caseObj.bio.customerNameInChinese'
                  label="Customer Name In Chinese"
                  placeholder="Customer Name In Chinese"
                ></v-text-field>
                <v-text-field
                  dense
                  v-model='caseObj.bio.phoneNumber'
                  label="Phone Number"
                  placeholder="Phone Number"
                ></v-text-field>

                <v-text-field
                  dense
                  v-model='caseObj.bio.emailAddress'
                  label="Email Address"
                  placeholder="Email Address"
                ></v-text-field>

                <v-text-field
                  dense
                  v-model='caseObj.bio.customerAddress'
                  label="Customer Address"
                  placeholder="Customer Address"
                ></v-text-field>

                <v-select
                  dense
                 :item-text="item => item.agentName +' ('+ item.agentFranchasieName + ')'"
                  item-value="id"
                  v-model='caseObj.bio.agentId'
                  label="Select an Agent"
                  :items='agentsList'
                ></v-select>

                <v-select
                  dense
                  v-if='role === "admin"'
                  item-text="name"
                  item-value="id"
                  v-model='caseObj.bio.franchiseeId'
                  label="Select a Franchisee (Admin Only)"
                  :items='franchiseeList'
                ></v-select>

                <v-select
                  label='Case Type'
                  :items="caseTypeArr"
                  v-model='caseObj.caseType'
                >
                </v-select>

              </v-col>

            </v-row>
            <v-row>
              <v-col cols='6'>
                <div class='mt-4'>
                  <h3 class='mb-3'>Customer Request Info</h3>
                  <v-text-field
                    dense
                    v-model='caseObj.requests[0].rawRequest.maidType'
                    label="外傭種類"
                    placeholder="外傭種類"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.maidNationality'
                    label="外傭國藉"
                    placeholder="外傭國藉"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.maidAge'
                    label="要求外傭年齡"
                    placeholder="要求外傭年齡"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.area'
                    label="地區 (18區)"
                    placeholder="地區 (18區)"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.familySize'
                    label="家庭人數"
                    placeholder="家庭人數"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.pet'
                    label="寵物 (種類及數目)"
                    placeholder="寵物 (種類及數目)"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.numberOfElderly'
                    label="長者數目,性別 及 歲數"
                    placeholder="長者數目,性別 及 歲數"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.elderlyNeedCare'
                    label="長者-需/不需照顧"
                    placeholder="長者-需/不需照顧"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.children'
                    label="小孩性別, 數目 及 歲數"
                    placeholder="小孩性別, 數目 及 歲數"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.childrenNeedCare'
                    label="小孩-需/不需照顧"
                    placeholder="小孩-需/不需照顧"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.holidayArrangement'
                    label="假期安排 (必須平日)"
                    placeholder="假期安排 (必須平日)"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.maidRoomArrangement'
                    label="傭工房間安排 (自己房/需家中成員同房)"
                    placeholder="傭工房間安排 (自己房/需家中成員同房)"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.numCCTV'
                    label="閉路電視"
                    placeholder="閉路電視"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.houseSQFT'
                    label="家居呎數"
                    placeholder="家居呎數"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.languageNeeded'
                    label="所需語言"
                    placeholder="所需語言"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.dailyCare'
                    label="日間是否需要照顧在家成員"
                    placeholder="日間是否需要照顧在家成員"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.workDesc'
                    label="工作範圍"
                    placeholder="工作範圍"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.otherMaid'
                    label="家中有否其他外傭"
                    placeholder="家中有否其他外傭"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.maidWorkDate'
                    label="外傭上班日期"
                    placeholder="外傭上班日期"
                  ></v-text-field>
                  <v-text-field
                    denes
                    v-model='caseObj.requests[0].rawRequest.notes'
                    label="其他備註"
                    placeholder="其他備註"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols='6'>
                <div class='mt-4'>
                  <h3 class='mb-3'>Staff Adjusted</h3>
                  <v-select
                    dense
                    :items="['Oversea','Local','No preference']"
                    label="Status"
                    v-model="caseObj.requests[0].adjustedRequest.status"
                  ></v-select>
                  <v-select
                    dense
                    label="Nationality"
                    :items="['Filipino','Indonesian','No preference']"
                    class="mt-5 mb-5"
                    v-model="caseObj.requests[0].adjustedRequest.nationality"
                  ></v-select>
                  <v-range-slider
                    v-model="caseObj.requests[0].adjustedRequest.age"
                    :max="65"
                    :min="18"
                    hide-details
                    class="align-center"
                  >
                    <template v-slot:prepend>
                      <v-text-field
                        :value="caseObj.requests[0].adjustedRequest.age[0]"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="$set(caseObj.requests[0].adjustedRequest.age, 0, $event)"
                      ></v-text-field>
                    </template>
                    <template v-slot:append>
                      <v-text-field
                        :value="caseObj.requests[0].adjustedRequest.age[1]"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @change="$set(caseObj.requests[0].adjustedRequest.age, 1, $event)"
                      ></v-text-field>
                    </template>
                  </v-range-slider>
                  <v-text-field
                    class='mt-5'
                    disabled
                  ></v-text-field>
                  <v-select
                    :items="['1-2','3-4','5+']"
                    label="familyMember"
                    v-model="caseObj.requests[0].adjustedRequest.familyMember"
                  ></v-select>
                  <v-checkbox
                    value="caseObj.requests[0].adjustedRequest.carePet"
                    v-model="caseObj.requests[0].adjustedRequest.carePet"
                    prepend-icon='mdi-paw'
                    label="care Pet"
                  ></v-checkbox>
                  <v-text-field disabled></v-text-field>
                  <v-checkbox
                    value="caseObj.requests[0].adjustedRequest.careElderly"
                    v-model="caseObj.requests[0].adjustedRequest.careElderly"
                    prepend-icon='mdi-human-cane'
                    label="care Elderly"
                  ></v-checkbox>

                  <v-checkbox
                    label="Care of Infant"
                    v-model="caseObj.requests[0].adjustedRequest.careInfant"
                    prepend-icon='mdi-baby-carriage'
                  ></v-checkbox>

                  <v-checkbox
                    value="caseObj.requests[0].adjustedRequest.careChild"
                    v-model="caseObj.requests[0].adjustedRequest.careChild"
                    prepend-icon='mdi-human-male-child'
                    label="care Child"
                  ></v-checkbox>
                  <v-text-field disabled></v-text-field>
                  <v-select
                    :items=this.yesno
                    label="Sharing room with babies/children/elderly?"
                    v-model="caseObj.requests[0].adjustedRequest.privateRoom"
                    required
                  ></v-select>
                  <v-checkbox
                    label="CCTV OK?"
                    v-model="caseObj.requests[0].adjustedRequest.ccTvOk"
                    prepend-icon='mdi-cctv'
                  ></v-checkbox>

                  <v-checkbox
                    label="Married"
                    v-model="caseObj.requests[0].adjustedRequest.married"
                    prepend-icon='mdi-ring'
                  ></v-checkbox>

                  <v-checkbox
                    label="Highest Education > High School?"
                    v-model="caseObj.requests[0].adjustedRequest.highschoolPlus"
                    prepend-icon='mdi-school'
                  ></v-checkbox>

                  <v-text-field disabled></v-text-field>
                  <v-row>
                    <v-col>
                      <v-select
                        :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                        item-text="text"
                        item-value="val"
                        label="Cantonese Skill >="
                        v-model="caseObj.requests[0].adjustedRequest.cantonese"
                        required
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                        item-text="text"
                        item-value="val"
                        label="English Skill >="
                        v-model="caseObj.requests[0].adjustedRequest.english"
                        required
                      ></v-select>
                    </v-col>
                    <v-col>
                      <v-select
                        :items="[{text : 'Excellent', val : 4 },{text : 'Good', val : 3 },{text : 'Fair', val : 2 },{text : 'Need to Learn', val : 1 }]"
                        item-text="text"
                        item-value="val"
                        label="Mandarin Skill >="
                        v-model="caseObj.requests[0].adjustedRequest.mandarin"
                        required
                      ></v-select>
                    </v-col>
                  </v-row>
                  <v-text-field disabled></v-text-field>
                  <v-text-field disabled></v-text-field>
                  <v-text-field disabled></v-text-field>
                  <v-row>
                    <v-col>
                      <v-menu
                        v-model="menuFrom"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="caseObj.requests[0].adjustedRequest.dateFrom"
                            label="Available From"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="caseObj.requests[0].adjustedRequest.dateFrom"
                          @input="menuFrom = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col>
                      <v-menu
                        v-model="menuTo"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="caseObj.requests[0].adjustedRequest.dateTo"
                            label="Available To"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="caseObj.requests[0].adjustedRequest.dateTo"
                          @input="menuTo = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                  </v-row>

                  <v-select
                    clearable
                    deletable-chips
                    chips
                    :items='["Philippines", "Indonesia"
                    , "Hong Kong"
                    , "Singapore"
                    , "Taiwan"
                    , "Middle East"
                    ,"Others"
                    ]'
                    multiple
                    label="Work Exp in Countries"
                    v-model="caseObj.requests[0].adjustedRequest.workexp"
                  ></v-select>

                </div>
              </v-col>
            </v-row>
          </v-form>
          <p style='height : 70px'></p>
          <v-btn
            color='primary'
            :loading='saveUpdateLoading'
            @click='editMode ? edit() : save()'
          >{{editMode ? 'Update' : 'Save'}}</v-btn>
          <v-btn
            class='ml-4'
            @click='search'
            :loading='searchLoading'
          >Search</v-btn>
          <p style='height : 170px'></p>
        </v-container>

      </v-tab-item>
      <v-tab-item key="files">

        <v-card>

          <v-card-title>
            <span class="text-h5">Files</span>
            <v-spacer></v-spacer>
            <v-btn
              :loading='uploading'
              @click='uploadDoc'
            >Upload File</v-btn>
            <input
              id='docid'
              type='file'
              @change="HandleFilechange"
              hidden
            />
          </v-card-title>

          <v-list-item
            :key="index"
            v-for="(file,index) in fileList"
          >

            <v-list-item-content>
              <a
                @click='downloadURL(file)'
                v-text="file.name"
              ></a>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon>
                <v-icon
                  @click="deleteFile(file)"
                  color="grey lighten-1"
                >mdi-delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>

        </v-card>

      </v-tab-item>
    </v-tabs>
    <v-dialog
      v-model="formsDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar
          dark
          color="primary"
        >

          <v-toolbar-title>Forms</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="formsDialog = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <caseDialogForms
          :maidObj='maidObj'
          :caseObj="caseObj"
        />
      </v-card>
    </v-dialog>

    <Maidbottomsheet
      @placeHold='placehold'
      @closeSheet='()=>{sheet = false}'
      :sheet='sheet'
      :searchResult='caseObj.searchResult'
      :held='caseObj.heldMaid !== null'
    />

  </div>
</template>

<script>
import invoice from "../components/invoice.vue";
import interviewTable from "../components/interviewTable.vue";
import store from "../store/index";
import unHoldMaidBtn from "../components/unHoldMaidBtn.vue";
import caseDialogForms from "./caseDialogForms.vue";
import momenttz from "moment-timezone";
import { db } from "../firebase/firebaseConfig";
import { getDocs } from "firebase/firestore";
import {
  searchHelpersForCase,
  getHelperByPortfolioId,
} from "../dataservices/helperDataservies";
import {
  uploadCaseFile,
  getCaseFiles,
  createNewCase,
  updateCase,
} from "../dataservices/caseDataservices";
import {
  getAgents,
  getFranchisees,
} from "../dataservices/appSettingsDataservices";
import Maidbottomsheet from "../components/MaidBottomSheet.vue";
import { getAllInterviewByCaseId } from "../dataservices/interviewDataservices";
export default {
  components: {
    caseDialogForms,
    unHoldMaidBtn,
    Maidbottomsheet,
    interviewTable,
    invoice,
  },
  props: {
    editMode: Boolean,
    editcaseObj: Object,
  },
  async mounted() {
    try {
  // Retrieve the role and asynchronously fetch data
  this.role = store.getters.role;

  const [agentsList, franchiseeList,query] = await Promise.all([
    getAgents(),
    getFranchisees(),
    getAllInterviewByCaseId(this.$route.params.id),
  ]);

  // Assign fetched data directly to properties
  this.agentsList = agentsList;
  this.franchiseeList = franchiseeList;


  // Process query snapshot
  query.onSnapshot(docs => {
    this.interviewData = docs.docs.map(doc => doc.data());
  });

  if (this.editMode) {
    this.caseObj = this.editcaseObj;
    this.caseObj.processStatus ? this.caseObj.processStatus : this.caseObj.processStatus = [];
    const { heldMaid, requests, bio } = this.caseObj;

    if (heldMaid !== null) {
      this.maidObj = await this.getMaidId();
      this.disableHeldMaidTextField = true;
      this.disableHeldMaidBtn = true;
    }

    const firstRequest = requests[0].adjustedRequest;
    firstRequest.workexp = firstRequest.workexp || [];
    firstRequest.careInfant = firstRequest.careInfant ?? null;

    this.fileList = await getCaseFiles(bio.franchiseeId, this.$route.params.id);

    this.$emit("finishLoading");
    this.loadInvoiceModal = true;
  }

  this.caseObj.searchResult = this.searchResultRaw;
} catch (error) {
  console.error("An error occurred:", error);
  // Handle the error appropriately
}
  },
  methods: {
    async getMaidId() {
      let query = await getHelperByPortfolioId(this.caseObj.heldMaid);
      let docs = await getDocs(query);
      let temp = docs.docs.map((d) => ({ id: d.id, ...d.data() }));
      return temp[0];
    },
    goToMaidPage() {
      if (this.caseObj.heldMaid !== null) {
        let routeData = this.$router.resolve({
          name: `/helpers/${this.maidObj.id}`,
        });
        window.open(routeData.route.name, "_blank");
      } else {
        return;
      }
    },
    uploadDoc() {
      document.getElementById("docid").click();
    },

    async HandleFilechange(e) {
      this.uploading = true;
      var file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = async (e) => {
        let image = e.target.result;
        this.base64 = image;
        this.base64 = this.base64.split(",")[1];
        this.fileName = file.name;
        this.fileType = file.type;

        await this.uploadFile();
        this.uploading = false;
        location.reload();
      };
      reader.readAsDataURL(file);
    },

    async uploadFile() {
      await uploadCaseFile(
        this.$route.params.id,
        this.fileName,
        this.base64,
        this.fileType,
        this.caseObj.bio.franchiseeId
      );
    },

    async updateStatus() {
      if (
        this.caseObj.caseStatus !== "Looking for Helper" &&
        this.caseObj.caseStatus !== "No need Anymore"
      ) {
        this.validateToProcessing();

        if (this.errorMessageObj.length > 0) {
          this.caseObj.caseStatus = "Looking for Helper";
        }
      }

      await db.collection("cases").doc(this.caseObj.id).update({
        caseStatus: this.caseObj.caseStatus,
      });
    },
    validateToProcessing() {
      this.errorMessageObj = [];
      if (!this.caseObj.heldMaid) {
        this.errorMessageObj.push("Please select a maid");
      }
    },
    validatePrintForms() {
      this.errorMessageObj = [];
      if (!this.caseObj.heldMaid) {
        this.errorMessageObj.push("Please select a maid");
      }
      if (!this.caseObj.bio.customerFirstName) {
        this.errorMessageObj.push("Please enter customer first name");
      }
      if (!this.caseObj.bio.customerLastName) {
        this.errorMessageObj.push("Please enter customer last name");
      }

      if (!this.caseObj.agencyFee) {
        this.errorMessageObj.push("Please enter agency fee for this case");
      }
      if (!this.caseObj.contractSignDate) {
        this.errorMessageObj.push(
          "Please enter contract sign date for this case"
        );
      }

      if (!this.maidObj.FirstNameOnIDPassport) {
        this.errorMessageObj.push(
          "Pleae Enter maid's First Name on ID/Passport"
        );
      }

      if (!this.maidObj.LastNameOnIDPassport) {
        this.errorMessageObj.push(
          "Pleae Enter maid's Last Name on ID/Passport"
        );
      }

      if (!this.maidObj.passportNum) {
        this.errorMessageObj.push("Pleae Enter maid's Passport Number");
      }

      if (!this.caseObj.bio.agentId) {
        this.errorMessageObj.push("Agent Info is needed");
      }
      if (!this.caseObj.bio.franchiseeId) {
        this.errorMessageObj.push("Franchisee Info is needed");
      }
      if (this.errorMessageObj.length === 0) {
        this.formsDialog = true;
      } else {
        return;
      }
    },
    async validateMaid() {
      this.holdLoading = true;
      if (this.caseObj.heldMaid == null) {
        this.holdLoading = false;
        return alert(
          "Please Fill in Maid Id to be held or click search button to select a maid"
        );
      }
      let query = await getHelperByPortfolioId(this.caseObj.heldMaid);
      let docs = await getDocs(query);

      let teampitem = docs.docs.map((d) => ({ id: d.id, ...d.data() }));
      if (teampitem[0].valid == "No") {
        this.caseObj.heldMaid = null;
        this.holdLoading = false;
        return alert("this maid is not available");
      } else {
        await db
          .collection("cases")
          .doc(this.caseObj.id)
          .update({
            heldMaid: this.caseObj.heldMaid,
            heldMaidTime: momenttz()
              .tz("Asia/Hong_Kong")
              .format("DD-MM-YYYY HH:mm:ss"),
          });

        this.maidObj = await this.getMaidId();
        await db.collection("Helpers").doc(this.maidObj.id).update({
          caseId : this.$route.params.id
        })
      }
      this.sheet = false;

      this.disableHeldMaidTextField = true;
      this.disableHeldMaidBtn = true;
      this.holdLoading = false;
    },
    openLink(id) {
      let routeData = this.$router.resolve({ name: `/helpers/${id}` });
      window.open(routeData.route.name, "_blank");
    },
    onUnholdComplete() {
      this.caseObj.heldMaid = null;
      this.caseObj.caseStatus = "Looking for Helper";
      this.disableHeldMaidTextField = false;
      this.disableHeldMaidBtn = false;
      this.sheet = false;
    },
    async placehold(maid) {
      this.caseObj.heldMaid = maid.portfolioId;
      this.validateMaid();
    },
    async edit() {
      //validate the form on update
      this.saveUpdateLoading = true;
      let result = this.$refs.form.validate();
      if (!result) {
        this.saveUpdateLoading = false;
        return;
      }
      await updateCase(this.caseObj);
      if (this.maidObj.id) {
        await db
          .collection("Helpers")
          .doc(this.maidObj.id)
          .set(
            {
              FirstNameOnIDPassport: this.maidObj.FirstNameOnIDPassport
                ? this.maidObj.FirstNameOnIDPassport
                : "",
              MiddleNameOnIDPassport: this.maidObj.MiddleNameOnIDPassport
                ? this.maidObj.MiddleNameOnIDPassport
                : "",
              LastNameOnIDPassport: this.maidObj.LastNameOnIDPassport
                ? this.maidObj.LastNameOnIDPassport
                : "",

              nationality: this.maidObj.nationality,
              passportNum: this.maidObj.passportNum
                ? this.maidObj.passportNum
                : "",
              idNumber: this.maidObj.idNumber ? this.maidObj.idNumber : "",
            },
            { merge: true }
          );
      }

      this.saveUpdateLoading = false;
      alert("Case Updated.");
    },
    async save() {
      //validate form on save
      this.saveUpdateLoading = true;
      let result = this.$refs.form.validate();
      if (!result) {
        this.saveUpdateLoading = false;
        return;
      }

      await createNewCase(this.caseObj);
      this.saveUpdateLoading = false;
      this.$router.push("/cases/all");
    },
    async search() {
      this.searchLoading = true;
      const searchResultRaw = await   
      searchHelpersForCase();
      this.searchResultRaw = searchResultRaw.sort(
    (a, b) => new Date(b.submitDate).getTime() - new Date(a.submitDate).getTime()
  );


      this.caseObj.searchResult = this.searchResultRaw;


      
      //filter age
      this.caseObj.searchResult = this.caseObj.searchResult.filter((r) => {
        return (
          r.age < this.caseObj.requests[0].adjustedRequest.age[1] &&
          r.age > this.caseObj.requests[0].adjustedRequest.age[0]
        );
      });

      //filter by status
      if (
        this.caseObj.requests[0].adjustedRequest.status &&
        this.caseObj.requests[0].adjustedRequest.status !== "No preference"
      ) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) => r.status === this.caseObj.requests[0].adjustedRequest.status
        );
      }

      //filter by workexp
      if (this.caseObj.requests[0].adjustedRequest.workexp.length !== 0) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter((obj) =>
          this.caseObj.requests[0].adjustedRequest.workexp.every((country) =>
            obj.workExp.some((exp) => exp.country === country)
          )
        );
      }

      //filter by care Pet

      if (this.caseObj.requests[0].adjustedRequest.carePet) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) => r.carePet >= 3
        );
      }

      //filter by care Elderly

      if (this.caseObj.requests[0].adjustedRequest.careElderly) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) => r.careElderly >= 3
        );
      }

      //filter by care Child

      if (this.caseObj.requests[0].adjustedRequest.careChild) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) => r.careChild >= 3
        );
      }
      // filter by CCTV
      if (this.caseObj.requests[0].adjustedRequest.ccTvOk) {
       
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) => r.ccTvOk
        );
      }


       // filter by Married
       if (this.caseObj.requests[0].adjustedRequest.married) {
       
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) => r.maritalStatus === "Married"
        );
      }


       // filter by Highest Education > High School?
       if (this.caseObj.requests[0].adjustedRequest.highschoolPlus) {

        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) => r.education !== "Junior High" && r.education !== "High School"
        );
      }

      //filter by careInfant

      if (this.caseObj.requests[0].adjustedRequest.careInfant) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) => r.careInfant >= 3
        );
      }
      // filter by nationality if selected
      if (
        this.caseObj.requests[0].adjustedRequest.nationality &&
        this.caseObj.requests[0].adjustedRequest.nationality !== "No preference"
      ) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) =>
            r.nationality ===
            this.caseObj.requests[0].adjustedRequest.nationality
        );
      }

      // filter by language
      if (this.caseObj.requests[0].adjustedRequest.english > 0) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) => r.english >= this.caseObj.requests[0].adjustedRequest.english
        );
      }
      if (this.caseObj.requests[0].adjustedRequest.mandarin > 0) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) => r.mandarin >= this.caseObj.requests[0].adjustedRequest.mandarin
        );
      }
      if (this.caseObj.requests[0].adjustedRequest.cantonese > 0) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) =>
            r.cantonese >= this.caseObj.requests[0].adjustedRequest.cantonese
        );
      }

      // filter by date
      if (
        this.caseObj.requests[0].adjustedRequest.dateFrom &&
        this.caseObj.requests[0].adjustedRequest.dateTo
      ) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (r) =>
            this.$moment(r.availabilityDate).isAfter(
              this.caseObj.requests[0].adjustedRequest.dateFrom
            ) &&
            this.$moment(r.availabilityDate).isBefore(
              this.caseObj.requests[0].adjustedRequest.dateTo
            )
        );
      }

      // filter by Sharing room with babies/children/elderly
      if (this.caseObj.requests[0].adjustedRequest.privateRoom === "yes") {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (n) => n.roomShare === "Yes"
        );
      }

      //filter by family size
      if (this.caseObj.requests[0].adjustedRequest.familyMember !== null) {
        this.caseObj.searchResult = this.caseObj.searchResult.filter(
          (n) =>
            n.preferredfamilysize === "no preference" ||
            n.preferredfamilysize ===
              this.caseObj.requests[0].adjustedRequest.familyMember
        );
      }

      this.sheet = true;
      this.searchLoading = false;
    },

    async downloadURL(fileRef) {
      let url = await fileRef.getDownloadURL();
      return window.open(url, "_blank");
    },
    async deleteFile(fileRef) {
      await fileRef.delete();
      location.reload();
    },
    onPaste(evt) {
      let clipboardData = evt.clipboardData.getData("text");

      if (!clipboardData.includes("要求外傭年齡")) {
        return;
      } else {
        evt.preventDefault();
        clipboardData = clipboardData.split("\n");
        const regex = /^.+:/;
        clipboardData = clipboardData.map((element) => {
          element = element.replace(/[\uff1a]/g, ":");
          let result = element.replace(regex, "");
          return result.trim();
        });
        this.caseObj.requests[0].rawRequest.maidType = clipboardData[0];
        this.caseObj.requests[0].rawRequest.maidNationality = clipboardData[1];
        this.caseObj.requests[0].rawRequest.maidAge = clipboardData[2];
        this.caseObj.requests[0].rawRequest.area = clipboardData[3];
        this.caseObj.requests[0].rawRequest.familySize = clipboardData[4];
        this.caseObj.requests[0].rawRequest.pet = clipboardData[5];
        this.caseObj.requests[0].rawRequest.numberOfElderly = clipboardData[6];
        this.caseObj.requests[0].rawRequest.elderlyNeedCare = clipboardData[7];
        this.caseObj.requests[0].rawRequest.children = clipboardData[8];
        this.caseObj.requests[0].rawRequest.childrenNeedCare = clipboardData[9];
        this.caseObj.requests[0].rawRequest.holidayArrangement =
          clipboardData[10];
        this.caseObj.requests[0].rawRequest.maidRoomArrangement =
          clipboardData[11];
        this.caseObj.requests[0].rawRequest.numCCTV = clipboardData[12];
        this.caseObj.requests[0].rawRequest.houseSQFT = clipboardData[13];
        this.caseObj.requests[0].rawRequest.languageNeeded = clipboardData[14];
        this.caseObj.requests[0].rawRequest.dailyCare = clipboardData[15];
        this.caseObj.requests[0].rawRequest.workDesc = clipboardData[16];
        this.caseObj.requests[0].rawRequest.otherMaid = clipboardData[17];
        this.caseObj.requests[0].rawRequest.maidWorkDate = clipboardData[18];
        this.caseObj.requests[0].rawRequest.notes = clipboardData[19];
      }
    },
  },
  data() {
    return {
      loadInvoiceModal: false,
      invoiceModal: false,
      InterviewTableHeaders: [
        { text: "Request Time", value: "requestTime" },
        { text: "Maid Portfolio Id", value: "maidPortfolioId" },
        { text: "Status", value: "status" },
        { text: "Meeting", value: "meetinglink" },
        { text: "Action", value: "action" },
      ],
      interviewData: [],
      disableHeldMaidTextField: false,
      disableHeldMaidBtn: false,
      role: null,
      fileList: [],
      base64: null,
      fileName: null,
      fileType: null,
      uploading: false,
      errorMessageObj: [],
      errorMessage: null,
      maidObj: {
        FirstNameOnIDPassport: "",
        MiddleNameOnIDPassport: "",
        LastNameOnIDPassport: "",
        nameOnIDPassport: "",
        nationality: "",
        idNumber: "",
        passportNum: "",
      },
      formsDialog: false,
      holdLoading: false,
      Maidsitems: [],
      franchiseeList: [],
      agentsList: [],
      caseTypeArr: [
        "Local FC",
        "Special Cases",
        "Oversea",
        "Renew",
        "Direct Hire",
      ],
      sheet: false,
      saveUpdateLoading: false,
      searchLoading: false,
      statusArr: [
        "Looking for Helper",
        "Processing",
        "Completed",
        "No need Anymore",
      ],
      requiredRule: [(v) => !!v || "Item is required"],
      hkIDRule: [
        (v) =>
          !v ||
          /^[A-Z]{1,2}[0-9]{6}\([0-9A]\)$/.test(v) ||
          "Please Enter a Valid HKID Number",
      ],
      menuFrom: false,
      menuSignDate: false,
      menuTo: false,
      yesno: ["yes", "no"],
      caseObj: {
        visaAppliedComment: "",
        visaApprovedComment: "",
        contractVerifiedComment: "",
        readyForFlightComment: "",
        processStatus : [],
        agencyFee: "",
        contractSignDate: "",
        heldMaid: null,
        id: "",
        caseStatus: "Looking for Helper",
        caseNotes: null,
        caseType: null,
        bio: {
          customerAddress: "",
          customerId: null,
          customerFirstName: "",
          customerMiddleName: "",
          customerLastName: "",
          customerNameInChinese: "",
          customerid: null,
          agentId: null,
          phoneNumber: "",
          franchiseeId: null,
          emailAddress: "",
        },
        searchResult: [],

        requests: [
          {
            id: "",
            rawRequest: {
              maidType: null,
              maidNationality: null,
              maidAge: null,
              area: null,
              familySize: null,
              pet: null,
              numberOfElderly: null,
              elderlyNeedCare: null,
              children: null,
              childrenNeedCare: null,
              holidayArrangement: null,
              maidRoomArrangement: null,
              numCCTV: null,
              houseSQFT: null,
              languageNeeded: null,
              dailyCare: null,
              workDesc: null,
              otherMaid: null,
              maidWorkDate: null,
              notes: null,
            },
            adjustedRequest: {
              nationality: null,
              status: null,
              age: [18, 65],
              familyMember: null,
              careElderly: null,
              carePet: null,
              careChild: null,
              cantonese: null,
              mandarin: null,
              english: null,
              dateFrom: null,
              dateTo: null,
              careInfant: null,
              workexp: [],
              privateRoom: null,
            },
          },
        ],
      },
    };
  },
};
</script>

<style>
</style>
