<template>
  <div>
    <v-snackbar v-model="snackbar">
      {{ messageText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <h1>Admin Tools</h1>
    <v-row no-gutters>
      <v-col
        class='ma-3'
        cols="12"
        sm="12"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
          <v-card-title>
            Grant Admin Role to User
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model='email'
              placeholder="Enter User Email"
            >
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled='!email'
              @click='assignAdmin'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        class='ma-3'
        cols="12"
        sm="12"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
          <v-card-title>
            Add Franchisee
          </v-card-title>
          <v-form ref='franForm'>
            <v-card-text>
              <v-text-field
                v-model='franchiseeCode'
                placeholder="Enter Franchisee Code"
                :rules="requiredRule"
                required
              >
              </v-text-field>
              <v-text-field
                v-model='franchiseeEmail'
                placeholder="Enter User Email"
              >
              </v-text-field>
              <v-text-field
                v-model='franchiseePassword'
                placeholder="Enter User Password (at least 6 characters)"
              >
              </v-text-field>
              <v-text-field
                v-model='franchiseePhone'
                placeholder="Enter User Phone Number"
              >
              </v-text-field>
              <v-text-field
                v-model='franchiseeName'
                placeholder="Enter Company Name"
              >
              </v-text-field>
              <v-text-field
                v-model='franchiseeAddress'
                placeholder="Enter Company Address"
              >
              </v-text-field>
              <v-btn @click='upload'>Upload Logo</v-btn>
              <input
                id='fileid'
                type='file'
                @change="Handlechange"
                hidden
              />
              <img
                class='ma-5'
                v-if='base64'
                height='100'
                :src="`data:image/png;base64,${base64}`"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn
                :loading='loading'
                @click='createNewFranchisee'
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>

      <v-col
        class='ma-3'
        cols="12"
        sm="12"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
          <v-card-title>
            Add user to Franchisee
          </v-card-title>
          <v-form ref='addUserToFranForm'>

            <v-card-text>
              <v-text-field
                :rules="requiredRule"
                required
                v-model='addUserId'
                placeholder="Enter User Id"
              >
              </v-text-field>
              <v-select
                :items='franchisees'
                item-text='name'
                item-value='id'
                v-model='addFranId'
                placeholder="Select Franchisee"
              >
              </v-select>

            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn
              :loading='agentLoading'
              @click='AddUserToFranchisee'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col
        class='ma-3'
        cols="12"
        sm="12"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
          <v-card-title>
            Create new user (staff role)
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model='Staffemail'
              placeholder="Enter User Email"
            >
            </v-text-field>
            <v-text-field
              v-model='staffName'
              placeholder="Enter User's Preferred Name"
            >
            </v-text-field>

          </v-card-text>
          <v-card-actions>
            <v-btn
              :disabled='!Staffemail'
              @click='assignStaff'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>

      
      <v-col
        class='ma-3'
        cols="12"
        sm="12"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
          <v-card-title>
            Create new Agent (not user)
          </v-card-title>
          <v-form ref='agentForm'>

            <v-card-text>
              <v-text-field
                :rules="requiredRule"
                required
                v-model='agentCode'
                placeholder="Enter Agent Code"
              >
              </v-text-field>
              <v-text-field
                v-model='agentName'
                placeholder="Enter Agent Name"
              >
              </v-text-field>

              <v-select
                :items='franchisees'
                item-text='name'
                item-value='id'
                v-model='agentFranchasieId'
                placeholder="Select Franchisee"
              >
              </v-select>

            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn
              :loading='agentLoading'
              @click='createAgent'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>


      <v-col
        class='ma-3'
        cols="12"
        sm="12"
      >
        <v-card
          class="pa-2"
          outlined
          tile
        >
          <v-card-title>
           Disable Agent
          </v-card-title>
          <v-form ref='disagentForm'>

            <v-card-text>
              <v-select
              :item-text="item => item.agentName +' ('+ item.agentFranchasieName + ')'"
                :items='agents'
                item-value='id'
                v-model='agentFranchasieId'
                placeholder="Select Agent"
              >
              </v-select>
           
            </v-card-text>
          </v-form>
          <v-card-actions>
            <v-btn
              :loading='disagentLoading'
              @click='disableAgent'
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    
    </v-row>
  </div>
</template>

<script>
import {
  createNewFranchisee,
  createNewStaff,
  createNewAdmin,
  createNewAgent,
  disableAgent,
  AddUserToFranchisee,
  getAllAgents
} from "../dataservices/adminDataservices";
import { getFranchisees } from "../dataservices/appSettingsDataservices";
export default {
  async mounted() {
    this.franchisees = await getFranchisees();
    this.agents = await getAllAgents();
  },
  data() {
    return {
      disagentLoading : false,

      agents : [],
      fileName: null,
      fileType: null,
      uploading: false,
      base64: "",
      agentLoading: false,
      agentFranchasieId: null,
      agentFranchasieName: null,
      agentName: null,
      agentCode: null,
      franchisees: [],
      loading: false,
      snackbar: false,
      messageText: ``,
      email: null,
      franchiseeEmail: null,
      franchiseeAddress: null,
      franchiseeCode: null,
      Staffemail: null,
      staffName: null,
      franchiseePassword: null,
      franchiseePhone: null,
      franchiseeName: null,
      requiredRule: [(v) => !!v || "This field is required"],
      addUserId: null,
      addFranId: null,
    };
  },
  methods: {
    async disableAgent(){
      this.disagentLoading = true;
      await disableAgent(this.agentFranchasieId);
      this.disagentLoading = false;
    },
    upload() {
      document.getElementById("fileid").click();
    },
    async Handlechange(e) {
      this.uploading = true;
      var file = e.target.files[0];
      let reader = new FileReader();
      reader.onload = async (e) => {
        let image = e.target.result;
        this.base64 = image;
        this.base64 = this.base64.split(",")[1];
        this.fileName = file.name;
        this.fileType = file.type;
      };
      reader.readAsDataURL(file);
    },
    async createAgent() {
      let valid = this.$refs.agentForm.validate();
      if (!valid) {
        return;
      }
      this.agentLoading = true;
      await createNewAgent(
        this.agentFranchasieId,
        this.agentName,
        this.agentCode,
        this.franchisees.find((f) => f.id === this.agentFranchasieId).name
      );
      this.agentFranchasieId = null;
      this.agentName = null;
      this.agentCode = null;
      this.agentFranchasieName = null;
      this.agentLoading = false;
    },
    async AddUserToFranchisee() {
      let franCode = this.franchisees.find(
        (f) => f.id === this.addFranId
      ).franchiseeCode;
      let res = await AddUserToFranchisee(
        this.addUserId,
        this.addFranId,
        franCode
      );
      alert(res.data);
    },
    async createNewFranchisee() {
      let valid = this.$refs.franForm.validate();
      if (!valid) {
        return;
      }
      this.loading = true;
      try {
        await createNewFranchisee(
          this.franchiseeEmail,
          this.franchiseePassword,
          this.franchiseePhone,
          this.franchiseeName,
          this.franchiseeCode,
          this.base64,
          this.franchiseeAddress
        );

        this.loading = false;
        this.messageText = "New Franchisee Created";
        this.snackbar = true;
        this.franchiseeEmail = null;
        this.franchiseePassword = null;
        this.franchiseePhone = null;
        this.franchiseeName = null;
        this.franchiseeAddress = null;
      } catch (e) {
        this.loading = false;
        this.messageText = e.response.data.code;
        this.snackbar = true;
      }
    },
    async assignStaff() {
      let res = await createNewStaff(this.Staffemail, this.staffName);
      alert(res.data);
    },
    async assignAdmin() {
      let res = await createNewAdmin(this.email);
      alert(res.data);
    },
  },
};
</script>

<style>
</style>